/* For some reason need this styles as sometimes styled component css overrides dont work for rc-slider */

.rc-slider-rail,
.rc-slider-track {
  height: 10px;
}

.rc-slider-rail {
  background-color: #0E3348;
}

.rc-slider-tooltip-inner {
  font-size: 1.25rem;
  font-weight: 600;
  padding: 0.5rem 0.75rem;
  min-width: auto;
  height: 100%;
  line-height: 1;
  color: #fff;
  border-radius: 2rem; /* move to constant */
  box-shadow: initial;
  cursor: grab;
}

.rc-slider-tooltip-arrow.rc-slider-tooltip-arrow {
  bottom: 3px;
  left: 50%;
  margin-left: -8px;
  border-width: 10px 10px 0;
  border-top-color: #02B0FF;
}

.rc-slider-tooltip-inner,
.rc-slider-track {
  background-color: #02B0FF;
}

.rc-slider-handle {
  border: 4px solid #02B0FF;
  height: 28px;
  margin-top: -8px;
  width: 28px;
}
